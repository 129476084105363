export const serverStatus = "Live";

export const serverLink =
    serverStatus === "Dev"
        ? "http://localhost:4480/"
        // : "https://smartsourcingapi.com:5001/" //LUX MUNDI
        // : "https://smartsourcingapi.com:5002/" // BABA AHMED
        // : "https://smartsourcingapi.com:5003/" // AL-ANSAR
        // : "https://smartsourcingapi.com:5004/" // OLIVIA 
        : "https://smartsourcingapi.com:5005/" //COSMOPOLITAN

export const simpleFileUploadAPIKey = "305e0ddf925a3fc5dd34d314f8230349";

// export const projectName = "Baba-Ahmed University";
// export const projectPhone = "+2348035125748, +2348087555544";
// export const projectEmail = "info@babaahmeduniversity.edu.ng"
// export const projectAddress = "No 306, Sharada Phase I Kano State, Nigeria.";

// export const projectName = "Olivia University";
// export const projectPhone = "+25767800004";
// export const projectEmail = "info@oliviauniversity.com";
// export const projectAddress = "No 4&5, Avenue Mayugi, Mukaza, Bujumbura, Burundi";

// export const projectName = "Al-Ansar University";
// export const projectPhone = "+234 803 629 5382";
// export const projectEmail = "info@aum.edu.ng"
// export const projectAddress = "Al-Ansar University, Maiduguri, Nigeria";

// export const projectName = "Lux Mundi University";
// export const projectPhone = "+234 803 xxx xxxx";
// export const projectEmail = "info@luxmundiuniversity.edu.ng"
// export const projectAddress = "Lux Mundi University, Umuahia";

export const projectName = "Cosmopolitan University";
export const projectPhone = "+234 803 xxx xxxx";
export const projectEmail = "info@cosmopolitanuniversity.edu.ng"
export const projectAddress = "Cosmopolitan University, Abuja";

